<template>
  <div class="reward-postpone-info-block side-highlight-container">
    <p class="card-title">展延設定</p>
    <el-alert
      v-show="noTaskTypeOptions"
      style="margin-bottom: 20px;"
      title="提醒："
      type="warning"
      show-icon
      center
      :closable="false"
    >
      <p>此店家目前未開啟相關獎勵回饋模組，因此無法進行設定。 如有相關需求請與歐巴小幫手聯繫，以取得協助。</p>
    </el-alert>
    <BaseElFormItem label="異動類型" prop="info.type" label-position="left" label-width="127px">
      <SelectWrapper>
        <BaseElSelect v-model="syncForm.type" style="width: 220px" clearable :disabled="noTaskTypeOptions" @change="onTaskTypeChange">
          <BaseElSelectOption
            v-for="status in taskTypeOptions"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </BaseElSelect>
      </SelectWrapper>
    </BaseElFormItem>
    <BaseElFormItem v-if="syncForm.type === rewardPostponeTaskTypesConfig.pointYearlyExtension.value" label="選擇點數" prop="info.shopPoint">
      <BaseDataSelect
        :value.sync="syncForm.shopPoint"
        :dataList="displayShopPointOptions"
        placeholder="選擇點數"
        @change="onShopPointChange"
      />
    </BaseElFormItem>
    <BaseElFormItem label="展延後有效日期" prop="info.expAt">
      <el-date-picker
        v-model="syncForm.expAt"
        :disabled="disabledDatePicker"
        editable
        type="date"
        :picker-options="pickerOptions"
      />
    </BaseElFormItem>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref, nextTick } from 'vue'
import { rewardPostponeTaskTypesConfig } from '@/config/rewardPostpone'
import { useVModel } from '@vueuse/core'
import dayjs from '@/lib/dayjs'
import store from '@/store'
import { omit, get, isEmpty, filter } from 'lodash'
import SelectWrapper from '@/components/SelectWrapper.vue'
import { useShop } from '@/use/shop'
import BaseDataSelect from '@/components/Select/BaseDataSelect.vue'
import { GetShopPointList } from '@/api/shopPoint'

export default defineComponent({
  name: 'RewardPostponeInfoBlock',
  components: { SelectWrapper, BaseDataSelect },
  props: {
    form: { type: Object, defalt: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)
    const { shopId } = useShop()
    const marketingPermission = computed(() => store.getters.computedShopMarketingPermission)
    const noTaskTypeOptions = computed(() => isEmpty(taskTypeOptions.value))
    const shopPointList = ref([])
    const displayShopPointOptions = computed(() => {
      return filter(shopPointList.value, { type: 'year' })
    })

    const disabledDatePicker = computed(() => {
      if (syncForm.value.type === rewardPostponeTaskTypesConfig.pointYearlyExtension.value) {
        if (!syncForm.value.shopPoint) return true
      }

      return false
    })

    const taskTypeOptions = computed(() => {
      const omitList = []
      if (!get(marketingPermission.value, 'point')) omitList.push('pointYearlyExtension')
      if (!get(marketingPermission.value, 'cashback')) omitList.push('cashbackYearlyExtension')

      return omit(rewardPostponeTaskTypesConfig, omitList)
    })

    const pickerOptions = {
      disabledDate: (date) => {
        const nextYear = dayjs().add(1, 'year')

        // 年度錢包展延
        if (syncForm.value.type === rewardPostponeTaskTypesConfig.pointYearlyExtension.value) {
          const shopPointExpDate = get(syncForm.value, 'shopPoint.annualSettlementDate')
          const startDate = dayjs(`${dayjs().year()}/${shopPointExpDate}`).endOf('date')
          const endDate = dayjs(`${nextYear.year()}/${shopPointExpDate}`).endOf('date')
          return dayjs(date).isBefore(startDate) || dayjs(date).isAfter(endDate)
        }

        const minDate = dayjs().set('month', 1).set('date', 1).startOf('date')
        const maxDate = nextYear.set('month', 0).set('date', 31).endOf('date')
        return dayjs(date).isBefore(minDate) || dayjs(date).isAfter(maxDate)
      },
    }

    const getShopPointList = async () => {
      const [res, err] = await GetShopPointList({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      shopPointList.value = res
    }

    const onTaskTypeChange = () => {
      syncForm.value.shopPoint = null
      syncForm.value.expAt = null
    }

    const onShopPointChange = async (shopPoint) => {
      await nextTick()
      const shopPointExpDate = get(shopPoint, 'annualSettlementDate')
      const startDate = dayjs(`${dayjs().year()}/${shopPointExpDate}`).endOf('date')
      syncForm.value.expAt = startDate
    }

    onMounted(() => {
      getShopPointList()
      const newYear = dayjs().set('month', 1).set('date', 1).startOf('date')
      // 自動預設:
      setTimeout(() => {
        if (!noTaskTypeOptions.value) {
          if (!get(marketingPermission.value, 'point')) syncForm.value.type = rewardPostponeTaskTypesConfig.cashbackYearlyExtension.value
          else if (!get(marketingPermission.value, 'cashback')) syncForm.value.type = rewardPostponeTaskTypesConfig.pointYearlyExtension.value
          else syncForm.value.type = rewardPostponeTaskTypesConfig.pointYearlyExtension.value
        }

        if (syncForm.value.type === rewardPostponeTaskTypesConfig.cashbackYearlyExtension.value) {
          syncForm.value.expAt = newYear
        }
      }, 100)
    })

    return {
      rewardPostponeTaskTypesConfig,
      syncForm,
      taskTypeOptions,
      marketingPermission,
      noTaskTypeOptions,
      pickerOptions,
      displayShopPointOptions,
      disabledDatePicker,
      onTaskTypeChange,
      onShopPointChange,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
